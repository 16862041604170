.help {
    &__wrapper {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -10px;

        @include media(1024px) {
            margin: 0;
        }

        .block-help {
            width: calc(50% - 20px);
            margin: 0 10px;

            @include media(1024px) {
                width: 100%;
                margin: 0 0 20px 0;
            }
        }
    }
}

.block-help {
    padding: 40px;
    background-color: $background_color;
    line-height: 1.3;
    font-weight: 500;

    @include media(1024px) {
        padding: 20px;
    }

    p {
        margin: 0;
        
        &:not(:last-child) {
            margin-bottom: 20px;
        }
    }

    strong, b {
        color: $main_color;
        font-weight: 500;
    }
}