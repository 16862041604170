.about {
  padding: 50px 0 100px;

  @include media(1440px) {
    padding: 25px 0 75px;
  }

  @include media(1024px) {
    padding: 40px;
  }

  @include media(600px) {
    padding: 0 0 40px;
  }

  &__title {
    margin-bottom: 30px;
    font-size: 68px;
    font-weight: 700;
    line-height: 1.2;
    letter-spacing: -0.04em;

    @include media(1400px) {
      margin-bottom: 30px;
      font-size: 56px;
    }

    @include media(1024px) {
      font-size: 38px;
    }

    @include media(600px) {
      margin-bottom: 20px;
      font-size: 30px;
    }

    @include media(450px) {
      font-size: 26px;
    }
  }

  &__content {
    &.light-text {
      font-size: 20px;
      font-weight: 400;

      @include media(768px) {
        font-size: 18px;
        line-height: 26px;
      }

      @include media(600px) {
        font-size: 16px;
        line-height: 24px;
      }

      @include media(450px) {
        font-size: 14px;
        line-height: 20px;
      }
    }
  }

  &__content p {

    &:not(:first-child) {
      margin-top: 20px;

      @include media(1024px) {
        margin-top: 15px;
      }
    }
  }

  &__img {
    margin-bottom: 20px;
    margin-left: 60px;
    width: 49%;
    height: 635px;
    float: right;
    object-fit: contain;

    @include media(1400px) {
      margin-left: 35px;
      height: 600px;
    }

    @include media(1024px) {
      display: block;
      width: 100%;
    }
    
    @include media(480px) {
        height: auto;
    }
  }
  
  &__button {
    margin-top: 50px;

    @include media(1024px) {
      margin-top: 40px;
    }

    @include media(768px) {
      margin-top: 30px;
    }

    @include media(450px) {
      margin-top: 40px;
    }
  }
}

.block-video {
    position: relative;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: #393939;
    line-height: 1.3;
    text-align: center;
    
    &:hover {
        color: #1f3487;
        
        .block-video {
            &__ico {
                transform: translate(-50%, -50%) scale(1.1);
            }
        }
    }
    
    &__ico {
        display: block;
        width: 90px;
        height: 90px;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        fill: #fff;
        background: #1f3487;
        border-radius: 50%;
        transition: transform 0.3s;
        
        @include media(768px) {
            width: 60px;
            height: 60px;
        }
    }
    
    &__img {
        width: 100%;
        max-width: 100%;
        height: auto;
    }
    
    &__title {
        margin: 0;
        margin-top: 5px;
        color: #393939;
        line-height: 1.3;
        text-align: center;
    }
}

.video_youtube_items {
   display: flex;
   flex-wrap: wrap;
   justify-content: space-between;
}

.video_youtube_item {
   width: calc((100% - 40px) / 3);
   margin-bottom: 20px;
   
    @include media(768px) {
        width: calc((100% - 40px) / 2);
    }
    
    @include media(450px) {
        width: 100%;
        margin-bottom: 10px;
    }
}