.facts {
  padding: 100px 0 80px;

  @include media(1440px) {
    padding: 60px 0 70px;
  }

  @include media(1024px) {
    padding: 40px 0 50px;
  }

  @include media(600px) {
    padding: 20px 0 35px;
  }

  &__header {
    margin-bottom: 60px;
    font-size: 46px;
    font-weight: 500;
    line-height: 55px;
    letter-spacing: -0.04em;

    @include media(1440px) {
      margin-bottom: 40px;
      font-size: 44px;
      line-height: 53px;
      letter-spacing: normal;
    }
  
    @include media(1024px) {
      margin-bottom: 35px;
      font-size: 32px;
      line-height: 38px;
    }
  
    @include media(600px) {
      margin-bottom: 20px;
      font-size: 22px;
      line-height: 26px;
    }
  }

  &__wrapper {
    flex-wrap: wrap;
  }

  &__item {
    width: 47%;

    @include media(1024px) {
      width: 100%;
    }

    &:nth-child(n+3) {
      margin-top: 60px;
    }

    &:nth-child(n+2) {
      @include media(1024px) {
        margin-top: 40px;
      }

      @include media(600px) {
        margin-top: 35px;
      }
    }
  }

  &__title {
    margin-bottom: 30px;
    font-size: 34px;
    font-weight: 500;
    line-height: 34px;
    color: $main_color;

    @include media(1440px) {
      margin-bottom: 20px;
      font-size: 30px;
      line-height: 42px;
    }
  
    @include media(1024px) {
      margin-bottom: 15px;
      font-size: 22px;
      line-height: 31px;
    }
  
    @include media(600px) {
      margin-bottom: 10px;
      font-size: 14px;
      line-height: 20px;
    }
  }

  &__text {
    line-height: 34px;
    font-weight: 400;
    letter-spacing: -0.04em;
    color: $text_second_color;

    @include media(1440px) {
      line-height: 34px;
    }
  
    @include media(1024px) {
      line-height: 28px;
    }
  
    @include media(768px) {
      line-height: 23px;
      letter-spacing: normal;
    }
  
    @include media(450px) {
      line-height: 20px;
    }
  }
}