.popup {
  &__block {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    background-color: #fff;
    width: 544px;
    max-width: 90%;
    padding: 34px 40px 40px;
    box-sizing: border-box;
    text-align: center;

    @include media(600px) {
      padding: 34px 21px 40px;
    }

    &_reviews {
      position: relative;
      margin: auto;
      padding: 0;
      top: 0;
      left: 0;
      transform: none;

      & .popup__answer {
        margin-bottom: 0;
      }
    }
  }

  &__title {
    margin-bottom: 20px;
    display: block;
    font-size: 30px;
    font-weight: 400;
    line-height: 47px;
    color: $main_color;
    text-align: center;
    text-transform: uppercase;

    @include media(600px) {
      font-size: 20px;
      line-height: 28px;
    }
  }

  &__answer {
    margin: 0 auto 35px;
    display: block;
    max-width: 380px;
    font-size: 26px;
    font-weight: 300;
    line-height: 34px;
    letter-spacing: -0.04em;

    @include media(600px) {
      margin: 0 auto 20px;
      max-width: 260px;
      font-size: 18px;
      line-height: 27px;
    }

    &_color {
      display: inline;
      color: $main_color;
    }
  }

  &__img {
    margin: 0 auto 30px;
    padding: 45px 43px;
    width: 200px;
    height: 200px;
    border-radius: 50%;
    box-shadow: 0px 15px 20px rgba(46, 145, 121, 0.08);

    @include media(1024px) {
      width: 167px;
      height: 167px;
      padding: 38px 36px;
    }

    @include media(600px) {
      width: 108px;
      height: 108px;
      padding: 24px 23px;
    }
  }

  &__svg {
    width: 114px;
    height: 110px;
    fill: $main_color;

    @include media(1024px) {
      width: 95px;
      height: 91px;
    }

    @include media(600px) {
      width: 62px;
      height: 60px;
    }
  }
}

/* ************************************************* OLD styles **************************************************** */
.main_popup{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  background-color: rgba(0,0,0,0.5);
  display: none;
  opacity: 0;
  transition: 0.5s;
}

.main_popup.active{
  opacity: 1;
}

.main_popup .popup_close{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.main_popup .popup, .thanks_block{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  width: 544px;
  max-width: 90%;
  padding: 34px 40px 40px;
  box-sizing: border-box;
}

.popup_close_x{
  position: absolute;
  top: 15px;
  right: 15px;
  width: 15px;
  height: 15px;
  cursor: pointer;
}

.popup_close_x:before, .popup_close_x:after{
  content: '';
  height: 17px;
  width: 2px;
  border-radius: 5px;
  transform: rotate(45deg);
  position: absolute;
  background-color: #000;
  left: 7px;
  top: -1px;
}

.popup_close_x:before{
  transform: rotate(-45deg);
}

.popup_title, .thanks_title{
  display: block;
  text-transform: uppercase;
  font-size: 26px;
  color: $main_color;
  text-align: center;
  margin-top: 0;
}

.popup form{
  margin: 0;
}

.popup input, .popup textarea{
  width: 100%;
  height: 46px;
  border: 1px solid #d0d0d0 !important;
  font-size: 16px;
  position: relative;
}

.popup textarea{
  height: 170px;
  margin-bottom: 15px;
}

.popup_feedback_product p {
  margin-bottom: 15px;
}

.popup .placeholder{
  position: relative;

  &:not(:first-of-type) {
      margin-top: 25px;

      @include media(600px) {
          margin-top: 20px;
      }
  }
}

.popup .placeholder span{
  color: #f84141;
  font-size: 16px;
  position: absolute;
  top: 12px;
  z-index: 6;
  right: 10px;
  display: none;
}

.popup .placeholder input:required + span{
  display: block;
}

.popup .placeholder textarea:required + span{
  display: block;
}

.popup .placeholder input::placeholder,
.popup .placeholder textarea::placeholder {
  font-style: italic;
  font-size: 16px;
  font-weight: 300;
  line-height: 19px;
  line-height: 32px;

  @include media(600px) {
    font-size: 14px;
    line-height: 17px;
  }
}

.popup .placeholder textarea:-ms-input-placeholder {
  font-style: italic;
  font-size: 16px;
  font-weight: 300;
  line-height: 19px;
  line-height: 32px;

  @include media(600px) {
    font-size: 14px;
    line-height: 17px;
  }
}

.popup .placeholder input:-ms-input-placeholder {
  font-style: italic;
  font-size: 16px;
  font-weight: 300;
  line-height: 19px;
  line-height: 32px;

  @include media(600px) {
    font-size: 14px;
    line-height: 17px;
  }
}

.jq-number__field{
  width: 42px;
  height: 42px;
  border-radius: 0;
  border: none;
  box-shadow: none;
  
}

.jq-number{
  padding-right: 19px;
  margin: 10px 0 15px;
}

.jq-number__field input {
  border-radius: 0;
  color: #565656;
  text-align: center;
  height: 42px;
  font-weight: 600;
}

.jq-number__spin{
  width: 20px;
  height: 21px;
  border: 1px solid #d0d0d0;
  box-shadow: none;
  border-radius: 0;
  background: none;
}

.jq-number__spin:after{
  width: 6px;
  height: 6px;
  border: none;
  border-right: 1px solid #c1c1c1;
  border-top: 1px solid #c1c1c1;
  transform: rotate(-45deg);
  top: 8px;
  left: 6px;
}

.jq-number__spin.minus:after{
  border: none;
  border-right: 1px solid #c1c1c1;
  border-bottom: 1px solid #c1c1c1;
  transform: rotate(45deg);
}



@media (max-width: 450px){
  .main_popup .popup {
    padding-left: 20px;
    padding-right: 20px;
  }
}