.page-comand {
    overflow: hidden;
}

.comand__wrapper {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -50px;

    @include media(1200px) {
        margin: 0 -10px;
    }

    @include media(600px) {
        margin: 0;
    }

    .block-comand {
        width: calc(33.333% - 100px);
        margin: 0 50px;
        margin-bottom: 60px;

        @include media(1200px) {
            width: calc(50% - 20px);
            margin: 0 10px;
            margin-bottom: 40px;
        }

        @include media(600px) {
            width: 100%;
            margin: 0 0 40px 0;
        }
    }
}

.block-comand {
    &__img {
        width: 100%;
        height: auto;
        margin-bottom: 20px;
    }

    &__title {
        font-weight: 500;
        font-size: 30px;
        line-height: 1.3;
        color: #393939;

        @include media(600px) {
            font-size: 22px;
        }
    }

    &__desc {
        margin-top: 15px;
        font-weight: 400;
        font-size: 22px;
        line-height: 1.5;
        letter-spacing: -0.04em;
        color: #484848;

        @include media(600px) {
            margin-top: 5px;
            font-size: 16px;
        }
    }
}