.review {
  text-align: center;
  background-color: #fff;
  border: 1px solid rgb(244, 244, 244);
  border-radius: 10px;
  padding: 35px 35px 80px 35px;
  position: relative;
  @include media(700px) {
      padding: 25px 10px 55px;
  }
  .svg_icon {
      fill: #eaeaea;
      position: absolute;
      bottom: 15px;
      left: 50%;
      width: 64px;
      height: 40px;
      margin-left: -32px;
      @include media(700px) {
          height: 30px;
          bottom: 8px;
      }
  }
  &_name {
      font-weight: 500;
      font-size: 20px;
      color: #565656;
  }
  &_date {
      font-size: 14px;
      color: $button_color_first;
      font-weight: 400;
      padding: 5px 0;
  }
  &_text {
      font-size: 16px;
      color: #565656;
      font-weight: 300;
      @include media(700px) {
          font-size: 14px;
      }
      &_hidden {
          display: none;
      }
  }
  &_read {
      font-size: 14px;
      color: $button_color_first;
      border-bottom: 1px dashed;
      cursor: pointer;
      transition: all .25s linear;
      margin-top: 15px;
      display: inline-block;
      line-height: 1.25;
  }
}

.reviews {
  &.inner_page {
      flex-wrap: wrap;
      .review {
          width: 49%;
          margin-bottom: 40px;
          @include media(700px) {
              margin-bottom: 20px;
              width: 100%;
          }
      }
  }
}