.slider {
  margin: auto;
  max-width: $content_limit;
  color: $text_main_color;

  &__unit {
    padding: 60px 50px 80px;
    min-height: 500px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    @include media(1440px) {
      padding: 75px 0;
    }

    @include media(1024px) {
      padding: 45px 11px;
      min-height: 345px;
    }

    @include media(768px) {
      background-position: 10% center;
    }

    @include media(600px) {
      background-position: 15% center;
    }

    @include media(450px) {
      padding: 20px 0;
      min-height: 240px;
      background-position: 25% center;
    }

    .container {
      position: relative;
      min-height: calc(500px - 140px);

      @include media(1024px) {
        min-height: calc(345px - 90px);
      }

      @include media(450px) {
        min-height: calc(240px - 40px);
      }
    }

    // &:before {
    //   content: '';
    //   position: absolute;
    //   top: 0;
    //   left: 0;
    //   width: 39%;
    //   height: 100%;
    //   background-image: url(/assets/templates/img/dots_black.png);

    //   @include media(768px) {
    //     width: 58%;
    //   }

    //   @include media(450px) {
    //     width: 60%;
    //   }
    // }
  }

  &__title {
    margin-bottom: 35px;
    font-size: 68px;
    font-weight: 700;
    line-height: 1.1;
    max-width: 700px;

    @include media(1024px) {
      margin-bottom: 30px;
      font-size: 36px;
      max-width: 400px;
    }

    @include media(450px) {
      margin-bottom: 5px;
      padding: 0 10px;
      font-size: 28px;
    }
  }

  &__text {
    margin-bottom: 20px;
    font-size: 41px;
    font-weight: 400;
    line-height: 58px;

    @include media(1024px) {
      margin-bottom: 30px;
      font-size: 26px;
      line-height: 36px;
    }

    @include media(450px) {
      margin-bottom: 20px;
      padding: 0 10px;
      font-size: 20px;
      line-height: 30px;
    }
  }

  &__button {
    position: absolute;
    left: 30px;
    bottom: 0;
    z-index: 2;

    @include media(1660px) {
      left: 100px;
    }

    @include media(1300px) {
      left: 50px;
    }

    @include media(1200px) {
      left: 15px;
    }
  }
}
