.banner {
  position: relative;
  min-height: 460px;
  padding: 85px 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  @include media(1920px) {
    background-size: contain;
  }

  @include media(1024px) {
    min-height: 385px;
    padding: 65px 0;
    background-size: cover;
  }

  @include media(600px) {
    min-height: 270px;
    padding: 45px 0;
    
  }

  @include media(450px) {
    min-height: 200px;
    padding: 20px 0;
  }

  &_layer {
    &::before {
      content: '';
      position: absolute;
      top: 0;
      width: 60%;
      height: 100%;
      pointer-events: none;
    }

    &::before {
      left: 0;
      /*background: linear-gradient(89.81deg, #E9E9E9 38.84%, rgba(233, 233, 233, 0) 101.2%);*/
  
      @include media(1440px) {
        width: 60%;
      }
  
      @include media(600px) {
        width: 80%;
      }
  
      @include media(450px) {
        width: 100%;
      }
    }

    &_bg {
      color: #fff;

      &::before {
        width: 100%;
        background: linear-gradient(0deg, rgba(17, 34, 102, 0.3), rgba(17, 34, 102, 0.3));
      }

      h2 {
        color: #fff;
        font-size: 40px;
        line-height: 1.3;
        font-weight: 500;
        max-width: 75%;

        @include media(1024px) {
          max-width: 100%;
          font-size: 32px;
        }

        @include media(600px) {
          font-size: 28px;
        }
      
        @include media(450px) {
          font-size: 22px;
        }
      }
    }
  }


  &__info {
    max-width: 620px;
    font-weight: 500;
    color: $text_main_color;

    @include media(600px) {
      max-width: 380px;
    }

    @include media(450px) {
      max-width: 100%;
    }
  }

  &__pretitle {
    display: block;
    font-size: 30px;
    line-height: 44px;
    text-transform: uppercase;

    @include media(1024px) {
      max-width: 255px;
      font-size: 20px;
      font-weight: 400;
      line-height: 30px;
    }

    @include media(600px) {
      font-size: 16px;
    }

    @include media(450px) {
      max-width: 190px;
      font-size: 14px;
    }
  }

  &__title {
    font-size: 20px;
    line-height: normal;
    font-weight: 700;

    @include media(1024px) {
      font-size: 15px;
      line-height: normal;
    }

    @include media(600px) {
      font-size: 15px;
      line-height: normal;
    }

    @include media(450px) {
      font-size: 15px;
      line-height: normal;
    }
  }

  &__text {
    display: block;
    font-size: 18px;
    line-height: 30px;

    @include media(1024px) {
      margin-top: 20px;
      font-size: 16px;
      line-height: 24px;
    }

    @include media(600px) {
      font-size: 14px;
      line-height: 20px;
    }

    @include media(450px) {
      line-height: 17px;
    }
  }

  &__upper-text {
    margin-bottom: 60px;
    max-width: 1300px;
    font-size: 40px;
    font-weight: 500;
    line-height: 48px;
    letter-spacing: -0.04em;
    color: #fff;
    text-transform: uppercase;

    @include media(1440px) {
      margin-bottom: 45px;
      max-width: 980px;
      font-size: 30px;
      line-height: 44px;
    }

    @include media(1024px) {
      font-size: 26px;
      line-height: 36px;
      letter-spacing: normal;
    }

    @include media(600px) {
      margin-bottom: 20px;
      font-size: 14px;
      line-height: 22px;
    }
  }

  &__button {
    margin-top: 50px;

    @include media(1024px) {
      margin-top: 40px;
    }

    @include media(600px) {
      margin-top: 20px;
    }
  }
}
