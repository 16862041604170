.product {
  &__subtitle {
    margin-bottom: 10px;
    display: block;
    font-size: 30px;
    font-weight: 500;
    line-height: 36px;
    letter-spacing: -0.04em;

    @include media(1440px) {
      font-size: 28px;
      line-height: 34px;
    }

    @include media(1024px) {
      font-size: 26px;
      line-height: 31px;
    }

    @include media(600px) {
      font-size: 24px;
      line-height: 29px;
    }
  }

  &__header {
    margin-bottom: 70px;

    @include media(1024px) {
      margin-bottom: 60px;
      flex-direction: column;
    }

    @include media(600px) {
      margin-bottom: 40px;
    }
  }

  &__view {
    width: 47%;
    flex-wrap: wrap;
    align-self: baseline;

    @include media(1440px) {
      width: 38%;
      margin-right: 4.5%;
    }

    @include media(1024px) {
      width: 100%;
      margin-right: 0;
    }
  }

  &__img {
    width: 19.5%;
    height: 100px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    cursor: pointer;

    @include media(1440px) {
      height: 98px;
    }

    @include media(600px) {
      height: 43px;
    }

    &:nth-child(n+2) {
      margin-top: 25px;
      margin-right: 0.625%;

      @include media(600px) {
        margin-top: 15px;
      }
    }

    &:nth-child(5n+1) {
      margin-right: 0;
    }

    &:nth-child(n+7) {
      margin-top: 10px;
    }

    &_first {
      width: 100%;
      height: 520px;

      @include media(1440px) {
        height: 508px;
      }

      @include media(1024px) {
        height: 510px;
      }

      @include media(600px) {
        height: 220px;
      }
    }
  }

  &__info {
    width: 50%;

    @include media(1440px) {
      width: 57.5%;
    }

    @include media(1024px) {
      margin-top: 40px;
      width: 100%;
    }

    @include media(600px) {
      margin-top: 25px;
    }
  }

  &__price-info {
    margin-bottom: 40px;

    @include media(1024px) {
      margin-bottom: 30px;
      justify-content: space-between;
    }

    @include media(600px) {
      flex-flow: column;
      align-items: flex-start!important;
    }
  }

  &__price-text {
    min-width: 320px;

    @include media(600px) {
      margin-bottom: 30px;
      min-width: 100%;
    }
  }

  &__price {
    font-size: 38px;
    font-weight: 500;
    line-height: 46px;
    letter-spacing: -0.04em;

    @include media(1440px) {
      font-size: 44px;
      line-height: 30px;
    }

    @include media(1024px) {
      font-size: 38px;
    }

    @include media(600px) {
      font-size: 26px;
      line-height: 22px;
    }
  }

  &__intro {
    margin-bottom: 40px;

    @include media(600px) {
      margin-bottom: 30px;
    }
  }
}

.payment_item {
  width: 23%;
  margin-right: 2%;
  background-repeat: no-repeat;
  background-position: center;
  height: 70px;
  float: left;
  background-size: contain;
  
}

.payment_item:first-child, .payment_item:nth-child(5){
  background-position: center left;
}