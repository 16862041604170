$main_color: #0F2065;

$text_main_color: #393939;
$text_second_color: #484848;

$button_color_first: #0F2065;
$button_color_first_hover: #1F3487;
$button_color_second: #7166f1;
$button_color_second_hover: #524ba8;

$background_color: #F9F9F9;

$content_limit: 1660px;