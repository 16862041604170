.news {
  &__wrapper {
    flex-wrap: wrap;

    @include media(1024px) {
      flex-flow: column;
    }

    &::after {
      content: '';
      width: 29%;

      @include media(1440px) {
        width: 32%;
      }

      @include media(1024px) {
        content: none;
      }
    }
  }

  &__item {
    display: flex;
    flex-direction: column;
    width: 29%;

    @include media(1440px) {
      width: 32%;
    }

    @include media(1024px) {
      margin-top: 20px;
      width: 100%;
    }

    &:nth-child(n+4) {
      margin-top: 90px;

      @include media(1440px) {
        margin-top: 60px;
      }

      @include media(1024px) {
        margin-top: 20px;
      }
    }
  }

  &__img {
    margin-bottom: 30px;
    width: 100%;

    @include media(1024px) {
      display: none;
    }
  }

  &__title {
    margin-bottom: 20px;
    display: block;
    font-size: 30px;
    font-weight: 500;
    line-height: 39px;
    color: $button_color_first;
    text-decoration: underline;
    transition: all 0.5s;

    @include media(1440px) {
      font-size: 28px;
      line-height: 38px;
    }

    @include media(1024px) {
      margin-bottom: 10px;
      font-size: 16px;
      line-height: 24px;
    }

    &:hover {
      color: $button_color_first_hover;
      text-decoration: underline;
    }
  }

  &__intro {
    margin-bottom: 20px;
    display: block;
    font-size: 22px;
    font-weight: 400;
    line-height: 33px;
    letter-spacing: -0.04em;
    color: $text_second_color;
    height: 100%;

    @include media(1024px) {
      font-size: 18px;
      line-height: 23px;
    }

    @include media(600px) {
      margin-bottom: 10px;
      font-size: 14px;
      line-height: 20px;
    }
  }

  &__date,
  .views {
    font-size: 18px;
    font-weight: 500;
    line-height: 30px;
    letter-spacing: -0.04em;
    color: #999999;

    @include media(1024px) {
      font-size: 14px;
      line-height: 22px;
    }

    &_before {
      margin-right: 35px;
      padding-left: 28px;
      position: relative;
  
      &::before {
        content: '';
        position: absolute;
        top: -1px;
        left: 0;
        width: 14px;
        height: 100%;
        background-repeat: no-repeat;
        background-position: center;
        background-image: url(../img/calendar.png);
      }
    }
  }
}

.blog .news {

  &__img {
    margin-bottom: 0;
    width: 26.5%;
    height: 260px;
    align-self: baseline;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    @include media(1440px) {
      width: 22%;
      height: 216px;
    }

    @include media(1024px) {
      width: 32%;
      height: 143px;
      display: block;
    }

    @include media(600px) {
      margin-bottom: 15px;
      width: 100%;
    }
  }

  &__info {
    width: 69.5%;

    @include media(1440px) {
      width: 74.5%;
    }

    @include media(1024px) {
      width: 65%;
    }

    @include media(600px) {
      width: 100%;
    }
  }

  &__item {
    width: 100%;

    @include media(600px) {
      flex-direction: column;
    }

    &:not(:first-child) {
      margin-top: 50px;

      @include media(1024px) {
        margin-top: 40px;
      }

      @include media(600px) {
        margin-top: 30px;
      }
    }
  }

  &__intro {
    margin-bottom: 0;
    margin-top: 20px;

    @include media(1440px) {
      margin-top: 17px;
      -webkit-line-clamp: 4;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }

    @include media(1024px) {
      -webkit-line-clamp: 3;
    }

    @include media(600px) {
      margin-bottom: 0;
      -webkit-line-clamp: unset;
    }
  }
}

.article {
  max-width: 980px;
  margin: 0 auto;

  .help__wrapper {
    margin-top: 60px;
  }
}

.views {
  padding-left: 35px;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    top: -1px;
    left: 0;
    width: 22px;
    height: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url(../img/eye.png);
  }
}